import styled from 'styled-components';
import { font, bkptVal, breakpoint } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';
import { imageAndTitleLinkHover } from 'styles/imageAndTitleLinkHover';
import type { Variant } from '.';

function imageAspectRatio(variant: Variant, isRow: boolean) {
  if (isRow) {
    return '1.51/1';
  }
  switch (variant) {
    case 'hero':
    case 'card-xl':
    case 'card-lg':
    case 'card-sm':
      return '3/2';
    case 'card-md':
      return '16/9';
    default:
      return '16/9';
  }
}

function mobileWidth(isImage: boolean, displayExcerpt?: boolean, displayVertically?: boolean) {
  if (displayExcerpt && isImage) {
    return '43.75%';
  }
  if (displayVertically) {
    return '100%';
  }
  if (isImage && !displayExcerpt && !displayVertically) {
    return 'unset';
  }
  if (isImage) {
    return '30%';
  }
  return '70%';
}

function desktopWidth(isRow: boolean, displayExcerpt?: boolean) {
  if (!isRow) return '100%';
  if (isRow && displayExcerpt) return '30%';
  return 'unset';
}

export const Excerpt = styled.div`
  margin-bottom: 8px;
  font-family: ${font.graphikCompact};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Index = styled.div<{ theme: Theme }>`
  font-family: ${font.saolText};
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  color: ${getColor('textSecondary')};
  margin-top: -2px;
`;

/* eslint-disable import/prefer-default-export */
export const Container = styled.div<{
  theme: Theme;
  $variant: Variant;
  $isRow: boolean;
  $displayExcerpt?: boolean;
  $displayVertically?: boolean;
}>`
  container: cardContainer/inline-size;
  ${imageAndTitleLinkHover}
  width: 100%;
  display: flex;
  flex-direction: ${({ $displayVertically }) => ($displayVertically ? 'column' : 'row')};

  @media (${breakpoint.mdMin}) {
    flex-direction: ${({ $isRow }) => ($isRow ? 'row' : 'column')};
  }
  
  gap: ${({ $isRow }) => ($isRow ? '16px' : '0px')};

  .card-wrapper {
    display: flex;
    flex-direction: column;

    width: ${({ $displayExcerpt, $displayVertically }) => mobileWidth(false, $displayExcerpt, $displayVertically)};
    
    @media (${breakpoint.mdMin}) {
      width: ${({ $isRow, $displayExcerpt }) => ($isRow && $displayExcerpt ? '70%' : '100%')};
    }
  }

  .index {
    padding-bottom: 16px;
    border-bottom: 1px solid ${getColor('borderSecondary')};
    width: 100%;
  }

  .card-image {
    width: ${({ $displayExcerpt, $displayVertically }) => mobileWidth(true, $displayExcerpt, $displayVertically)};
    
    @media (${breakpoint.mdMin}) {
      width: ${({ $isRow, $displayExcerpt }) => desktopWidth($isRow, $displayExcerpt)};
    }
    
    margin-bottom: ${({ $isRow, $variant }) => !$isRow && (($variant === 'card-sm' && '8px') || '16px')};
    img {
      aspect-ratio: ${({ $variant, $isRow }) => imageAspectRatio($variant, $isRow)};
      object-fit: cover;
      
    }
}

  .card-eyebrow {
    font-family: ${font.graphikCond};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 150%;
    text-transform: uppercase;
    position: relative;
    margin-bottom: ${({ $variant }) => ($variant === 'hero' ? '16px' : '8px')};

    @media (-webkit-device-pixel-ratio: 2.125) and (min-width: 768px) and (max-width: 1024px) {
      font-size: 14px;
    }
  }

  .card-title {
    margin-bottom: ${({ $displayExcerpt, $variant }) => ($displayExcerpt || $variant === 'card-sm' ? '8px' : '16px')};

    ${({ $displayExcerpt }) =>
    $displayExcerpt &&
      `
        font-size: 20px;
    `} 
  }

  &.compact .card-title {
    margin-bottom: 8px;
  }

  .card-authors {
    margin-top: auto;
    line-height: 120%;
  }

  .card-date {
    color: ${getColor('textSecondary')};
    font-family: ${font.graphikCond};
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1.17px;
    line-height: 21px;
    margin-top: 2px;
  }
  
  @media (${breakpoint.mdMin}) {
    &.compact .card-title{
      margin-bottom: ${({ $variant }) => (['card-xl', 'card-sm'].indexOf($variant) > -1 ? '8px' : '16px')};
    }
  }
    
  @container cardContainer (min-width: ${bkptVal.sm}rem){
    .card-image {
      margin-bottom: ${({ $variant, $isRow }) => !$isRow && ($variant === 'hero' ? '24px' : '16px')};
    }
  }
`;
